<div class="main">
    <mat-tab-group animationDuration="200ms">
        <mat-tab label="OC TIERS " style="color: #e8431c;">

          <!--   <app-tickets-sav-oc-tiers
                *ngIf="$nbElement | async as nb; else noData"
                [totalItems]="nb"></app-tickets-sav-oc-tiers> -->
                <app-tickets-sav-oc-tiers></app-tickets-sav-oc-tiers>

        </mat-tab>
        <mat-tab label=" OC SFR" style="color: rgb(235, 16, 16);">
            <!-- <app-tickets-sav-oc-sfr
                *ngIf="$nbElementSfr | async as nb; else noData"
                [totalItems]="nb"></app-tickets-sav-oc-sfr> -->
                <app-tickets-sav-oc-sfr></app-tickets-sav-oc-sfr>
        </mat-tab>

        <!-- <ng-template #noData>
            <app-tickets-sav-oc-tiers [totalItems]=0></app-tickets-sav-oc-tiers>
            <app-tickets-sav-oc-sfr [totalItems]=0></app-tickets-sav-oc-sfr>
        </ng-template> -->

        

    </mat-tab-group>

</div>
