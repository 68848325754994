import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm, FormsModule, Validator, ReactiveFormsModule, FormControl, FormGroupDirective, Validators, FormGroupName, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import { UserModel } from 'src/app/models/UserModel';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted) );
  }
}

export default class CustomValidators {
  static match(controlName: string, matchControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const matchControl = controls.get(matchControlName);

      if (!matchControl?.errors && control?.value !== matchControl?.value) {
        matchControl?.setErrors({
          matching: {
            actualValue: matchControl?.value,
            requiredValue: control?.value
          }
        });
        return { matching: true };
      }
      return null;
    };
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  standalone: true,
  imports: [FormsModule,HttpClientModule,CommonModule,MatCardModule,MatInputModule,MatFormFieldModule,MatButtonModule,ReactiveFormsModule
]



})
export class RegisterComponent  implements OnInit{


registerForm : UserModel ={
  firstname:'',
  lastname:'',
  username:'',
  password:'',
  role:''

  
}


removeErrorMessage() {

}


  constructor(private loginService : LoginService, private router :Router , private adminService : AdminService){}


 

   /* validation formulaire sur le format de l'email */
   emailFormControl = new FormControl('', [Validators.required, Validators.email]);
   passwordFormControl= new FormControl('', Validators.required)
   //passwordFormControlBis = new FormControl('', [Validators.required, CustomValidators.match(this.passwordFormControl)])
   firstname = new FormControl('',Validators.required )
   lastname = new FormControl('',Validators.required )
   //role = new FormControl('',Validators.required )
    matcher = new MyErrorStateMatcher();




  ngOnInit(): void {
   
    this.getAllOps();

  }

  getAllOps(){


    this.adminService.getAllOps().subscribe(
      ops => console.log(ops)
      
    )
    
  }

  validateRegister() {
    this.registerForm.username = this.emailFormControl.value+'';
    this.registerForm.password= this.passwordFormControl.value+'';
    this.registerForm.firstname=this.firstname.value+'';
    this.registerForm.lastname=this.lastname.value+'';
    this.registerForm.role='USER';

    this.loginService.register(this.registerForm).subscribe(

      (reg ) => {

        if(reg.username)  {
          console.log(reg);
          
          this.router.navigateByUrl('/login');
        } else {
          this.router.navigateByUrl('/register')
        }
        
      },
      
    )
  
  
  }

}



