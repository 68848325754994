import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private loginService: LoginService, private router: Router) {}

  canActivate(): boolean {
    // Vérifiez si l'utilisateur est authentifié
    if (this.loginService.isAuthenticated() && this.loginService.isAdmin()) {
      console.log(this.loginService.currentUser);
      console.log(this.loginService.isAdmin())
      return true;
      // Vérifiez si l'uilisateur a le rôle "admin"
  
      } else {
        // Redirigez l'utilisateur s'il n'est pas admin
        this.router.navigate(['/access-denied']);
        return false;
      }
    
  }
}
