import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TicketsSav } from 'src/app/models/ticketModel';
import { TicketsOcTierModel } from 'src/app/models/ticketsOctiersModels';
import { LoginService } from 'src/app/services/login.service';
import { TicketService } from 'src/app/services/ticket.service';
import { DetailTicketComponent } from '../detail-ticket/detail-ticket.component';
import { MessagesComponent } from '../messages/messages.component';
import { catchError, Observable, of, Subject, takeUntil } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-tickets-sav-oc-tiers',
  templateUrl: './tickets-sav-oc-tiers.component.html',
  styleUrls: ['./tickets-sav-oc-tiers.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({
        height: '*',
        minHeight: ''
      })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsSavOcTiersComponent implements OnInit, OnDestroy {


  dsp = "THDD";
  selection = new SelectionModel<TicketsOcTierModel>(true, []);
  showFirstLastButtons = true;
  isLoadingResults = true;
  page: number = 1;
  size: number = 10;
  nb = "Nombre de tickets par page";

  @Input() totalItems!: number;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: MatTableDataSource<TicketsOcTierModel>;
  filterValues: any;

  private unsubscribe$ = new Subject<void>();

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(private dialog: MatDialog, private ticketService: TicketService, private _snackBar: MatSnackBar, protected loginService: LoginService) {
    this.dataSource = new MatTableDataSource();
  };

  colums = ["numero_cas", "nom_oc", "statut_du_cas", "date_creation_cas", "date_cloture", "diag_en_cours_stit", "code_de_cloture", "diagnostic_tiers", "commentaire_resolution_oc", "idnt_accs_ftth", "lrc_idnt_immeuble", "pbo_refr_pbo", "element_impacte", "adresse", "codepostalimmeuble", "codeinseeimmeuble", "communeimmeuble"]

  displayedColumns = [
    'select',
    "numero_cas",
    "nom_oc",
    "statut_du_cas",
    "date_creation_cas",
    "date_cloture",
    "adresse",
    "codepostalimmeuble",
    "codeinseeimmeuble",
    "communeimmeuble",
  ]


  ngOnInit(): void {
   
  //  if(this.totalItems>0) this.getDataFromApi();
    this.getLocalTicketSav();
   
  }

  getDataFromApi() {
    this.ticketService.getAllTicketsSavTiersFromReferPlus('vue_sav_oc_tiers_thdd', this.page, this.size)
    .pipe(
     
      catchError((error)=> {
        let message ='Erreur lors de la récupération des tickets';
       this.displayMessage(message, ['snackBarWarn']);
       setTimeout(()=> {
        this._snackBar.dismiss()
      }, 3000);
        return of([])
      }),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((res :TicketsOcTierModel[]) => {
      console.log(res);
      
      this.dataSource = new MatTableDataSource(res);
  this.dataSource.sort = this.sort;
  this.dataSource.sort.start = 'desc';
  this.dataSource.paginator = this.paginator;
    })
  }

  getLocalTicketSav(){

    this.ticketService.getLocalTicketsSav('vue_sav_oc_tiers_thdd').subscribe(
      (res:TicketsOcTierModel[]) => {
        this.dataSource = new MatTableDataSource(res);
  this.dataSource.sort = this.sort;
  this.dataSource.sort.start = 'desc';
  this.dataSource.paginator = this.paginator;
      }
      
    )
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.filteredData);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TicketsOcTierModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    // @ts-ignore
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.numero_cas + 1}`;
  }



  getColumns(data: any[]): string[] {
    const columns: string[] = [];
    data.forEach(row => {
      Object.keys(row).forEach(col => {
        if (!columns.includes(col)) {
          columns.push(col);
        }
      });
    });

    return columns;
  }


  // /!\ Convert csv séparateur point virgule
  convertToCsv(data: any[], columns: string[]): string {
    let encoding = 'ANSI';
    let csv = '';
    csv += columns.join(';') + '\n';
    data.forEach(row => {

      const values: any[] = [];
      columns.forEach(col => {
        values.push(row[col] || '');
      });
      csv += values.join(';') + '\n';
    });
    return csv;
  }

  openDialog(data: any) {
    this.dialog.open(DetailTicketComponent,
      {
        data: data

      }
    )
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: TicketsOcTierModel, filter: string) => {
      const numero_cas = data.numero_cas ? data.numero_cas.toLowerCase() : '';
      const date_creation_cas = data.date_creation_cas ? data.date_creation_cas : '';
      const statut_du_cas = data.statut_du_cas ? data.statut_du_cas.toLowerCase() : '';
      const nom_oc = data.nom_oc ? data.nom_oc.toLowerCase() : '';
      const date_cloture = data.date_cloture ? data.date_cloture : '';
      const adresse = data.adresse ? data.adresse.toLowerCase() : '';
      const codepostalimmeuble = data.codepostalimmeuble ? data.codepostalimmeuble.toLowerCase() : '';
      const codeinseeimmeuble = data.codeinseeimmeuble ? data.codeinseeimmeuble.toLowerCase() : '';
      const communeimmeuble = data.communeimmeuble ? data.communeimmeuble.toLowerCase() : '';
      const dataStr = `${numero_cas} ${date_creation_cas} ${statut_du_cas} ${nom_oc} ${date_cloture} ${adresse} ${codepostalimmeuble} ${codeinseeimmeuble} ${communeimmeuble} `
      return dataStr.indexOf(filter) !== -1;

    };
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }

  exportToCsv() {
    const data = this.selection.selected.map(item => {
      return {
        ...item,
      }
    });
      
      const csvData = this.convertToCsv(data, this.colums);
      this.downloadSelectedTickets(csvData, `Tickets-OC-Tiers-${new Date().toLocaleDateString()}.csv`, 'text/csv;charset=utf-8;');
  }

  downloadSelectedTickets(data: string, filename: string, type: string) {
    const blob = new Blob([`\uFEFF${data}`], {type:type}  );
    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  displayMessage(message: String, _class: string[]) {
    this._snackBar.openFromComponent(MessagesComponent, {

      data: {
        message: message
      },
      verticalPosition: "bottom",
      panelClass: _class,

    })

  }


  onPageChange(event: any) {
    this.page = event.pageIndex + 1;
    this.size = event.pageSize;
    this.getDataFromApi();
  }

  ngOnDestroy(): void {

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }

}


