import {Component, OnInit} from '@angular/core';
import {codeErreurTickets, CodeErreurTickets} from "../../models/code-erreur-tickets";

@Component({
  selector: 'app-code-erreur-tickets',
  templateUrl: './code-erreur-tickets.component.html',
  styleUrls: ['./code-erreur-tickets.component.css']
})
export class CodeErreurTicketsComponent implements OnInit{

  codeErreurTickets : CodeErreurTickets[] = codeErreurTickets;

  ngOnInit(): void {

    console.table(this.codeErreurTickets);


  }

}
