import { Component } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-maia',
  templateUrl: './maia-container.component.html',
  styleUrls: ['./maia-container.component.css']
})
export class MaiaComponent {
  disable= false;
  constructor(protected login :LoginService){}

}
