import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LoginService } from './login.service';
import * as jwt_decode from "jwt-decode";
import { Router } from '@angular/router';
import { NotificationsService } from './notifications.service';

@Injectable()
export class JwtInterceptotService implements HttpInterceptor {

  constructor(private loginService: LoginService , private router : Router , private notificationService : NotificationsService) {}

  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       
      
       // Récupérer le token via le service
       const token = this.loginService.getToken();
      // Ne pas intercepter la requête de login (authenticate)

      
      if (req.url.includes('authenticate')) {
        return next.handle(req); // Laisser la requête passer telle quelle
      }
  
   
  
      // Clonage de la requête pour ajouter le token
      const clonedRequest = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
  
      // Utiliser la requête clonée
      return next.handle(clonedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {

            //console.error('Erreur 401 : Non autorisé', error);

            this.router.navigateByUrl('/login');
          }
          if(error.status === 403){
            console.log(error.message);
            this.router.navigateByUrl('/login');
          
          }
          return throwError(() => error); // On retourne l'erreur
        })
      );
    }
  
  
    isTokenExpired(token: string): boolean {
      if (!token) return true;
  
      const decoded = jwt_decode.jwtDecode(token);
      
      if (!decoded.exp) return true;
  
      const expirationDate = new Date(0);
      expirationDate.setUTCSeconds(decoded.exp);
  
      return expirationDate < new Date();
    }



 

  getRoleFromToken(token: string) {

    return jwt_decode.jwtDecode(token);

  }
}
