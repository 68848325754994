import { Component } from '@angular/core';

@Component({
  selector: 'app-ipe-container',
  templateUrl: './ipe-container.component.html',
  styleUrls: ['./ipe-container.component.css']
})
export class IpeContainerComponent {

  

}
