<header>

    <div *ngIf="($getUserInfo | async  )?.user"  class="userInfo">
    <i class="fa fa-user" style="color: #e8431c"></i>
        <div style="height: 2px">
           <p>{{($getUserInfo | async)?.user?.username}}</p>
            <p>{{($getUserInfo | async)?.user?.firstname}}  {{($getUserInfo | async)?.user?.lastname}}</p>
          <!--   <div *ngIf="loginService.isAdmin()" class="admin">

                <button>
                    <i class="fa fa-cog" style="color: #e8431c" routerLink="/admin"></i>
                </button>
    
            </div> -->
        </div>

       


    </div>
 

    <!-- <img src="/assets/img/logos/{{loginService.getQuadrigramme()}}.png" alt="logodsp"> -->

    <img src="/assets/img/logos/THDD.png" alt="logodsp">


    
  </header>
 