<div class="cards row">

  <mat-card  class="card col-2 col-sm-12" #ipe (click)="navTo('ipe')" style="cursor: pointer;" >
    <mat-card-header>
      <mat-card-title style="margin-bottom: 10px;">IPE</mat-card-title>
      
    </mat-card-header>

    <mat-card-content>
      <i class="fa fa-file " style="zoom: 3;background-color:white ; color: #e8431c"></i>
    </mat-card-content>
  </mat-card>

   <mat-card class="card col-2 col-sm-12" #tickets ngModel (click)="navTo('tickets-sav')" style="cursor: pointer;" >
    <mat-card-header>
      <mat-card-title style="margin-bottom: 10px;">TICKETS SAV</mat-card-title>
      <mat-divider></mat-divider>
    </mat-card-header>  
    <mat-card-content>
      <i class="fa fa-list " style="zoom: 3;background-color:white ; color: #e8431c ;"></i>
    </mat-card-content>
  </mat-card> 

  <mat-card  class="card col-2 col-sm-12" #dossiers (click)="navTo('base_rop')" style="cursor: pointer;">
    <mat-card-header>
      <mat-card-title style="margin-bottom: 10px;">ROP</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <i class="fa fa-file-archive-o " style="zoom: 3;background-color:white ; color: #e8431c" ></i>
       
    </mat-card-content>
  </mat-card>

  <mat-card class="card col-2 col-sm-12" #dossiers (click)="navTo('maia')" style="cursor: pointer;">
    <mat-card-header>
      <mat-card-title>MAIA</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <i class="fa fa-spinner " style="zoom: 3;background-color:white ; color: #e8431c"></i>
    </mat-card-content>
  </mat-card>  

</div>