import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {Router} from "@angular/router";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit , OnChanges {
  title = 'dashboard';

  constructor(private router: Router){
    console.log(this.router.getCurrentNavigation());
  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.router.getCurrentNavigation());
    
  }


  ngOnInit(): void {

   
    if(localStorage.getItem('currentUser')){
      this.router.navigateByUrl('/main-container')
    }
  }




 
}
