
  <div class="main" *ngIf="!disable">
    <mat-tab-group animationDuration="200ms">
        <mat-tab label="Extraction MAIA " style="color: #e8431c;">
       <app-maia-quotidien></app-maia-quotidien>
            
        </mat-tab> 
        <mat-tab label="Archive MAIA" style="color: rgb(235, 16, 16);">
            <app-maia-archive></app-maia-archive>
        </mat-tab>
        
    </mat-tab-group>

</div>

<app-underconstruction *ngIf="disable"></app-underconstruction>

