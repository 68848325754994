export interface CodeErreurTickets {
    "code" : string,
    "description": string,
}



 export const  codeErreurTickets: CodeErreurTickets[] = [

    {code:'ERR01', description:'SIG NON PRISE EN COMPTE : LE PRODUIT N EST PAS DANS VOTRE PARC PTO OI INTERVENTION SANS RDV ACTION A DEFINIR'},
    {code:'ERR02', description:'PRISE INCONNUE RACCO PALIER OC INTERVENTION AVEC RDV ACTION PREVUE REPONSE OPENACTIVE CASE SOLVING NOTIFICATION MANUEL'},
    {code:'ERR03', description:'INFORMATIONS INCOMPLETES PBO TIERS INTERVENTION DERCO ACTION PLANIFIEE INFO OPENACTIVE.TO.BE.CANCELED CASE SOLVED NOTIFICATION MANUEL (PLUS DE DISPO)'},
    {code:'ERR04', description:'SIG NON PRISE EN COMPTE : AUTRE SIG EN COURS DE TRAITEMENT PM-PBO NOUVELLE ACTION A PREVOIR AVANCEMENT INTERVENTION CLOSED CASE CANCELATION REQUEST MANUEL (ACCES PLAN DE CHARGE INDISPONIBLE)'},
    {code:'ERR05', description:'CLOTURE DU TICKET DE MAINTENANCE PROGRAMMEE PM ACTION ANNULEE DEMANDE RDV DEFERRED CASE CANCELATION AGREEMENT HORIZONTALE RESEAU'},
    {code:'ERR06', description:'LA DEMANDE DE RENSEIGNEMENT EST RESTEE SANS SUITE : TICKET CLOS HORIZONTALE RESEAU ACTION TERMINEE REPONSE RDV CASE CANCELATION'},
    {code:'ERR07', description:'INCIDENT ABANDONNE SUR DEMANDE DE L OC (ETAT QUEUED) COMMENTAIRE DDE ANNULATION SP CASE INFORMATION NOTIFICATION'},
    {code:'ERR08', description:'UNE RESILIATION A ETE REÇUE PROPOSITION DE RDV SU CASE INFORMATION NOTIFICATION'},

    {code:'RET01', description:'DEFAUT CONFIRME ET CORRIGE PAR OI'},
    {code:'RET02', description:'PRELOC ERRONEE, DEFAUT CORRIGE PAR OI'},
    {code:'RET03', description:'CLOTURE DU TICKET D INCIDENT GENERALISE (DERANGEMENT COLLECTIF)'},
    {code:'RET04', description:'DEFAUT CAUSE PAR UN TIERS IDENTIFIE'},
    {code:'RET05', description:'DEFAUT LIE A DES CAUSES EXCEPTIONNELLES (INTEMPERIES)'},
    {code:'RET06', description:'DEFAUT LIE A UN VANDALISME'},
    {code:'STT01', description:'PAS DE DEFAUT CONSTATE SUR LA PARTIE DE L OI'},
    {code :'STT02' , description: 'INCIDENT ABANDONNE SUR VOTRE DEMANDE(ETAT OPEN ACTIVE - INTERVENTION LANCEE)'},
    {code:'STT03' , description:   'DEFAUT DETECTE RESPONSABILITE OC'},
    {code : 'ABS01' , description: 'CLIENT ABSENT, NECESSITE D INTERVENIR CHEZ LUI, PAS DE RDV PLANIFIE'},
    {code :'ABS02' ,description :'CLIENT ABSENT LORS DU RDV'},




]



