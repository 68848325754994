import { Component, OnInit } from '@angular/core';
import { AlfrescoFileModel } from 'src/app/models/alfrescoNode';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-maia-archive',
  templateUrl: './maia-archive.component.html',
  styleUrls: ['./maia-archive.component.css']
})
export class MaiaArchiveComponent implements OnInit {

  loading: boolean = false;
  ticketPerPage: number=9;
  headers: string[] = [];
  lines: string[] = []; 
  errorMessage: string= '';
  size : number = 107000;
  fileListing : AlfrescoFileModel[]= []
  constructor(protected apiService :ApiService){}


  ngOnInit(): void {

    this.apiService.getFolderListArchiveMaia().subscribe(
      (file) => {
        this.fileListing= file.items;
        this.fileListing=this.fileListing.sort((a,b)=> {return  new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()})
      }
    )

    
   
  }

  downloadIpe(workspace: string, filename :string) {
    let node='';
     node=workspace.replace("workspace://SpacesStore/", '');
     this.apiService.getFileFromAlfresco(node, filename);
     
     }
}
