import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Ticket, TicketsSav} from 'src/app/models/ticketModel';
import { ApiService } from 'src/app/services/api.service';
import { DetailTicketComponent } from '../detail-ticket/detail-ticket.component';
import { TicketService } from 'src/app/services/ticket.service';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';




@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})


export class TicketsComponent implements OnInit, AfterViewInit {
dsp :string='THDD'
  length = 7998;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [10, 25 , 50 , 100];
  etat_ticket = "Ouvert";

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent!: PageEvent;
  constructor(private apiService: ApiService, private ticketService: TicketService, public dialog: MatDialog) { }
  ngAfterViewInit(): void {
    
  }
  data: TicketsSav[] = [];
  searchText: string = "";
  selectedTickets: TicketsSav[] = [];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined;
  ngOnInit(): void {
    this.loadData();


  }


  /* old ticket */
  loadData(): void {
    if(this.pageIndex===0) this.pageIndex=1;
    this.ticketService.getAllTicketsFromReferPlus(this.dsp).subscribe((data : any) => {
      if(data ) {
        this.data = data;
        //console.log(data.total.length);
      }
    });
  }


  checkAllCheckBox(ev: any) {
    this.data.forEach(x => x.checked = ev.target.checked)
    console.log(this.data);
    this.selectedTickets = this.data?.filter(t => t.checked == true)

  }
  isAllCheckBoxChecked() {
    return this.data.every(t => t.checked)
  }

  onChange(arg0: TicketsSav) {
    console.log(arg0);
    console.table(this.data);
    this.selectedTickets = this.data.filter(t => t.checked == true)
    console.table(this.selectedTickets);

  }


  headerTicket = ["Identifiant Cas",
    "V Type",
    "Plage date Ouverture",
    "Statut Cas",
    "Plage date  Résolution",
    "Numero Voie",
    "Type Voie",
    "Nom Voie",
    "Code Postal",
    "Commune",
    "Region",
    "Oc Tiers Declarant",
    "OI",
    "Hexacle",
    "Prise Client Impactee",
    "Reference Pm",
    " Reference PBO"];



  openDialog(ticket: Ticket) {
    this.dialog.open(DetailTicketComponent,
      {
        data: ticket
      }
    )
    console.log(ticket);

  }

  searchTicket(event: EventTarget) {
    console.log(event);

  }

  getColumns(data: any[]): string[] {
    const columns: string[] = [];
    data.forEach(row => {
      Object.keys(row).forEach(col => {
        if (!columns.includes(col)) {
          columns.push(col);
        }
      });
    });
    return columns;
  }


  // /!\ Convert csv séparateur point virgule
  convertToCsv(data: any[], columns: string[]): string {
    let encoding = 'UTF-8';
    let csv = '';
    csv += columns.join(';') + '\n';
    data.forEach(row => {
      const values: any[] = [];
      columns.forEach(col => {
        values.push(row[col] || '');
      });
      csv += values.join(';') + '\n';
    });
    return csv;
  }


  exportToCsv() {
    const data = this.selectedTickets;
    const columns = this.getColumns(data);
    const csvData = this.convertToCsv(data, columns);
    this.downloadSelectedTickets(csvData, 'Tickets.csv', 'text/csv');
  }

  downloadSelectedTickets(data: string, filename: string, type: string) {
    const blob = new Blob([data], { type: type });

    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }


  handlePageEvent(e: PageEvent) {
    this.pageEvent=e;
    this.pageIndex = e.pageIndex;
    this.pageSize=e.pageSize;
    console.log(e.pageIndex);
    this.loadData();
    console.log(e)

  }
}
