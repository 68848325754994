import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm, FormsModule, Validator, ReactiveFormsModule, FormControl, FormGroupDirective, Validators, FormGroupName } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';


import { Route, Router } from '@angular/router';

import { UserModel } from 'src/app/models/UserModel';
import { CommonModule } from '@angular/common';
import { Credentials } from 'src/app/models/Credentials';
import { FlexLayoutModule } from '@angular/flex-layout';

/* gestion des erreur du formulaire de login */

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted) );
  }
}



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [FormsModule, 
    HttpClientModule,
     MatCardModule,
      MatFormFieldModule, 
      MatInputModule, ReactiveFormsModule,MatButtonModule, CommonModule, FlexLayoutModule ]
})
export class LoginComponent implements OnInit {
  fullUrl : string ='';

  constructor(private loginService: LoginService, private http: HttpClient , private router :Router ) { }

  ngOnInit(): void {

   
    this.fullUrl = window.location.href;

    console.log(this.fullUrl);
    
  
    
    
    
  }
  loginSucces: boolean=true;
  errorMessage='Utilisateur ou mot de passe invalide !'

  credentials : Credentials ={
    username:'',
    password:''
   
    
  };
  

    /* validation formulaire sur le format de l'email */
    emailFormControl = new FormControl('', [Validators.required, Validators.email]);
    passwordFormControl= new FormControl('', Validators.required)

  matcher = new MyErrorStateMatcher();


  validateLogin() {
    // console.log(this.emailFormControl.value , this.passwordFormControl.value)
    if(this.emailFormControl.value != null) this.credentials.username = this.emailFormControl.value;
    if(this.passwordFormControl.value != null) this.credentials.password = this.passwordFormControl.value;
    // console.log(this.credentials); 
    this.loginService.isConnected = true;
   // this.router.navigateByUrl('/main-container')

   this.loginService.Login(this.credentials).subscribe(
    (res) =>  {

      if(res ){
       
        localStorage.setItem("currentUser", JSON.stringify(res));
        this.loginService.saveToken(res.token) ;   
        this.loginService.currentUserSubject.next(res);
        this.router.navigateByUrl('/main-container');
        this.loginSucces=true

      }
    },
    (error : any )=> {
      this.loginSucces=false
      console.log(error.status);
      // refresh token if error 403
      if(error.status === 403){

        localStorage.removeItem('currentUser');
        this.router.navigateByUrl('/login');
      }
      
    }
    
   )
    
    }


removeErrorMessage() {

  this.loginSucces=true;
  
}

    

}
