import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { PreviewFileComponent } from '../preview-file/preview-file.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseRop, displayedColumns } from 'src/app/models/baseRop';
import { SelectionModel } from '@angular/cdk/collections';
import { DetailTicketComponent } from '../detail-ticket/detail-ticket.component';
import { MessagesComponent } from '../messages/messages.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlfrescoFileModel } from 'src/app/models/alfrescoNode';
import { LoginService } from 'src/app/services/login.service';

const URLbaseRop = "assets/files/baseRop.json"

@Component({
  selector: 'app-base-rop',
  templateUrl: './base-rop.component.html',
  styleUrls: ['./base-rop.component.css']
})
export class BaseRopComponent implements OnInit {



  headers: string[] = [];
  lines: string[] = [];
  fileUrl: any;
  errorMessage: string = '';
  size: number = 0;
  dataSource: MatTableDataSource<BaseRop>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  disable= false;

  pageEvent:PageEvent | undefined;
   data:any;
  dsp = "THDD";
  dataLength=0;
  pageIndex=0;
  selection = new SelectionModel<BaseRop>(true, []);
  showFirstLastButtons = true;
  isLoadingResults = true;
  nb="Nombre de tickets par page"
  displayedColumns = displayedColumns
ipeListing: any;
files!: AlfrescoFileModel[] ;
 

  constructor(protected apiService: ApiService, private sanitizer: DomSanitizer, public dialog: MatDialog, private _snackBar: MatSnackBar, protected login :LoginService) { 
    this.dataSource = new MatTableDataSource();
  }


  ngOnInit(): void {

  this.apiService.getFolderAlfrescoBaseRop().subscribe(
    (res : any) => {


      this.files=res.items.reverse();
     this.files.sort((a : any,b: any)=> a.createdOn - b.createdOn);
      
    }
    
  )

  }

 
  downloadBaseRop(workspace: string, filename :string) {
    let node='';
     node=workspace.replace("workspace://SpacesStore/", '');
     this.apiService.getFileFromAlfresco(node, filename)
     
     }


  getColumns(data: any[]): string[] {
    const columns: string[] = [];
    data.forEach(row => {
      Object.keys(row).forEach(col => {
        if (!columns.includes(col)) {
          columns.push(col);
        }
      });
    });
    return columns;
  }


  // /!\ Convert csv séparateur point virgule
  convertToCsv(data: any[], columns: string[]): string {
    let encoding = 'UTF-8';
    let csv = '';
    csv += columns.join(';') + '\n';
    data.forEach(row => {
      
      const values: any[] = [];
      columns.forEach(col => {
        values.push(row[col] || '');
      });
      csv += values.join(';') + '\n';
    });
    return csv;
  }


  exportToCsv() {
    const data =this.selection.selected;
    const columns = this.getColumns(data);
    const csvData = this.convertToCsv(data, columns);
    this.download(csvData, 'Tickets.csv', 'text/plain;charset=UTF-8;base64');
  }


  displayMessage( message : String, _class : string[]){
    this._snackBar.openFromComponent(MessagesComponent, {

      data : {
        message :message
      },
      verticalPosition : "bottom",
      panelClass:_class,

    })

  }

  download(data: string, filename: string, type: string) {
    const blob = new Blob([data], {type:type} );

    

    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }


  openDialog() {
    this.dialog.open(PreviewFileComponent,
      {
        data:{
          lines : this.lines,
          headers : this.headers
        }
      }
    )

  }

  onsPageSelection(){
    console.log(this.pageEvent?.pageIndex, this.pageEvent?.pageSize, this.pageEvent?.length);
    
    

  }

}
