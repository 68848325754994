import {Component, OnDestroy, OnInit} from '@angular/core';
import { Event, NavigationEnd, Route, Router } from '@angular/router';
import {filter, fromEvent, Observable, Subject, Subscription, tap} from 'rxjs';
import { UserModel } from 'src/app/models/UserModel';
import { LoginService } from 'src/app/services/login.service';
import {UserInfo} from "../../models/userInfoModel";





@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
connected= false;
// @ts-ignore
  cnxIndo : UserInfo;
     $getUserInfo: Observable<UserInfo> | undefined;



  constructor(private router : Router , protected loginService :LoginService){}
  ngOnInit(): void {
         this.$getUserInfo = this.loginService.currentUser;
// @ts-ignore
    this.router.events.pipe(
      tap( (ev : any) => console.log(ev))
    )
  }

logoDsp: string= '';
getUrl(): any {
return "url('/assets/img/slider3.jpg')"
}



}
