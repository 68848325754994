import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { TicketsOcSfrModel } from 'src/app/models/ticketsOcSfrModels';
import { TicketsOcTierModel } from 'src/app/models/ticketsOctiersModels';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-tickets-sav-container',
  templateUrl: './tickets-sav-container.component.html',
  styleUrls: ['./tickets-sav-container.component.css']
})
export class TicketsSavContainerComponent implements OnInit , OnDestroy {

constructor(private ticketService : TicketService){}
 message="Synchronisation des tickets en cours de développement"
$vueOcTiers = new Observable<TicketsOcTierModel[]>;
$vueOcSfr = new  Observable<TicketsOcSfrModel[]>;
$nbElement= new Observable<number>;
$nbElementSfr = new Observable<number>;


  ngOnInit(): void {
   this.$nbElement= this.ticketService.getNumberOfData('vue_sav_oc_tiers_thdd');
   this.$nbElementSfr= this.ticketService.getNumberOfData('vue_sav_oc_sfr_thdd');
  }



  ngOnDestroy(): void {
  
  }

}
