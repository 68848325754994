import { DatePipe, NgForOf, LowerCasePipe, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login.service';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';


@Component({
  selector: 'app-dictionnaire',
  templateUrl: './dictionnaire.component.html',
  styleUrls: ['./dictionnaire.component.css'],
 
})
export class DictionnaireComponent {

  pdfSrc="assets/files/dictionnaire_de_donnees.pdf"

  safePdfurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc);

  constructor( private sanitizer: DomSanitizer){
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
    
  }



}
