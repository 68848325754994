<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center">

    <mat-card class="box" >
    
      <form class="form-card">
        <mat-form-field>
            <mat-label>Nom</mat-label>
            
            <input (keyup)="removeErrorMessage()" type="email" class="input-form"  matInput [formControl]="lastname" [errorStateMatcher]="matcher">
      
          </mat-form-field>
          <mat-form-field>
            <mat-label>Prenom</mat-label>
            
            <input (keyup)="removeErrorMessage()" type="email" class="input-form"  matInput [formControl]="firstname" [errorStateMatcher]="matcher">
      
          </mat-form-field>
        <mat-form-field>
          <mat-label>Email</mat-label>
          
          <input (keyup)="removeErrorMessage()" type="email" class="input-form"  matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher">
    
        </mat-form-field>
    
        <mat-form-field>
          <mat-label>Choix du mot de passe</mat-label>
          
          <input (keyup)="removeErrorMessage()" type="password"   matInput [formControl]="passwordFormControl" >
    
        </mat-form-field>
        <!-- <mat-form-field>
            <mat-label>Valider le mot de passe</mat-label>
            
            <input  type="password"   matInput [formControl]="passwordFormControlBis" >
      
          </mat-form-field> -->

       <!--  <mat-card-content>
          
            <span class=" btn btn-danger" *ngIf="!loginSucces">{{errorMessage}}</span>
        </mat-card-content> -->
    
        <mat-card-actions>
          <button mat-button color="primary" [disabled]="emailFormControl.invalid || passwordFormControl.invalid || lastname.invalid "
          (click)="validateRegister()"
          >Valider</button>
        </mat-card-actions>
    
    
      </form>
    
    </mat-card>
    </div>

