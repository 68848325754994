import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.css']
})
export class TemplateCardComponent {

constructor(protected apiService: ApiService){}


  data : any;


  download(arg0: any,arg1: any) {
    throw new Error('Method not implemented.');
    }

}
