import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminService  {

  
  constructor(private http: HttpClient) { }

  getAllUser(idDsp : string){
   return this.http.get(`/backend/api/auth/admin/${idDsp}`);

  }

  getAllOps(){

    return this.http.get(`/backend/operateurs/all`)
  }


}
