import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainContainerComponent} from './components/main-container/main-container.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {IpeComponent} from './components/ipe/ipe.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatDialogModule} from '@angular/material/dialog';
import {DetailTicketComponent} from './components/detail-ticket/detail-ticket.component';
import {FoldersComponent} from './components/folders/folders.component';
import {FormsModule} from '@angular/forms';
import {NgxSearchPipeModule} from 'ngx-search-pipe';
import {FooterComponent} from './components/footer/footer.component';
import {CardContainerComponent} from "./components/card-container/card-container.component";
import {HeaderComponent} from './components/header/header.component';
import {MatTabsModule} from '@angular/material/tabs';
import {FontsPipe} from './pipes/fonts.pipe';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {BaseRopComponent} from './components/base-rop/base-rop.component';
import {TemplateComponent} from './components/template/template.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FileSizePipe} from './pipes/file-size.pipe';
import {PreviewFileComponent} from './components/preview-file/preview-file.component';
import {LoginService} from './services/login.service';
import {JwtInterceptotService} from './services/jwt-interceptot.service';
import {PaginationComponent} from './components/pagination/pagination.component';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {IpeContainerComponent} from './components/ipe-container/ipe-container.component';
import {IpeQuotidienComponent} from './components/ipe-quotidien/ipe-quotidien.component';
import {MatSelectModule} from '@angular/material/select';
import {String2datePipe} from './pipes/string2date.pipe';
import {CodeErreurTicketsComponent} from './components/code-erreur-tickets/code-erreur-tickets.component'
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MessagesComponent } from './components/messages/messages.component';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSortModule} from "@angular/material/sort";
import {TableTicketsComponent} from "./components/table-tickets/table-tickets.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {NgbNavModule } from '@ng-bootstrap/ng-bootstrap'
import {MatDatepickerModule } from '@angular/material/datepicker';

import {ReactiveFormsModule} from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MaiaComponent } from './components/maia-container/maia-container.component';
import { MaiaQuotidienComponent } from './components/maia-quotidien/maia-quotidien.component';
import { MaiaArchiveComponent } from './components/maia-archive/maia-archive.component';
import { TemplateCardComponent } from './components/template-card/template-card.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { UnderconstructionComponent } from './components/underconstruction/underconstruction.component';
import { AdminComponent } from './components/admin/admin.component';
import { CustomPaginatorIntl } from './services/CustomPaginatorIntl';
import { TicketsSavContainerComponent } from './components/tickets-sav-container/tickets-sav-container.component';
import { TicketsSavOcTiersComponent } from './components/tickets-sav-oc-tiers/tickets-sav-oc-tiers.component';
import { TicketsSavOcSfrComponent } from './components/tickets-sav-oc-sfr/tickets-sav-oc-sfr.component';
import { DictionnaireComponent } from './components/dictionnaire/dictionnaire.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import { CartoIframeComponent } from './components/carto-iframe/carto-iframe.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';


registerLocaleData(localeFr);


@NgModule({
    declarations: [
        AppComponent,
        MainContainerComponent,
        IpeComponent,
        //TicketsComponent,
        FoldersComponent,
        FooterComponent,
        HeaderComponent,
        FontsPipe,
        BaseRopComponent,
        TemplateComponent,
        FileSizePipe,
        PreviewFileComponent,
        PaginationComponent,
        IpeContainerComponent,
        IpeQuotidienComponent,
        String2datePipe,
        CodeErreurTicketsComponent,
        TableTicketsComponent,
        MaiaComponent,
        MaiaQuotidienComponent,
        MaiaArchiveComponent,
        TemplateCardComponent,
        ErrorPageComponent,
        UnderconstructionComponent,
        AdminComponent,
        TicketsSavContainerComponent,
        TicketsSavOcTiersComponent,
        TicketsSavOcSfrComponent,
        DictionnaireComponent,
        CartoIframeComponent
     



    ],
   

    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatInputModule,
        MatToolbarModule,
        HttpClientModule,
        MatDialogModule,
        FormsModule,
        NgxSearchPipeModule,
        CardContainerComponent,
        MatTabsModule,
        MatProgressBarModule,
        MatCardModule,
        MatTableModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDividerModule,
        MatTooltipModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatFormFieldModule,
        NgbNavModule,
        MatSortModule,
        MatIconModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        NgxExtendedPdfViewerModule,
        MatRadioModule,
        MatExpansionModule
    ],
    exports: [
        CodeErreurTicketsComponent, DictionnaireComponent
    ],
    providers: [
        
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptotService, multi: true},
        { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
        {provide: LOCALE_ID, useValue: 'fr-FR'},
        LoginService 


    ]

})
export class AppModule {
}
