import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { RegisterComponent } from './components/register/register.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { AdminComponent } from './components/admin/admin.component';
import { AuthGuard } from './auth.guard';
import { CartoIframeComponent } from './components/carto-iframe/carto-iframe.component';

const routes: Routes = [
  {path:"login", component: LoginComponent},
  {path:"main-container", component: MainContainerComponent},
  {path:"Notfound", component : ErrorPageComponent},
  {path:"admin", component :AdminComponent , canActivate : [AuthGuard] },
  {path:"carto", component:CartoIframeComponent},
  {path:'**', redirectTo:"/login", pathMatch:"full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true, scrollPositionRestoration:'enabled'})],
  exports: [RouterModule],

})
export class AppRoutingModule { }
