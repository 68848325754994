import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-carto-iframe',
  templateUrl: './carto-iframe.component.html',
  styleUrls: ['./carto-iframe.component.css']
})
export class CartoIframeComponent {

  url :SafeResourceUrl 

  constructor(private dom : DomSanitizer){

    this.url = this.dom.bypassSecurityTrustResourceUrl('https://sfr-ftth.maps.arcgis.com/apps/webappviewer/index.html?id=081495bf6daf43d585011fdcb91d9cc6');

  }

}
