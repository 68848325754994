export interface BaseRop {

    rv_refr_cable_pbo: string,
        rv_coul_fibr_pbo: string,
        pm_coordonnee_y: string,
        pto_idnt_accs_ftth: string,
        rv_refr_pm: string,
        pbo_localisation: string,
        pm_coordonnee_x: string,
        lrc_refr_dossier: string,
        rv_position_pos: number,
        rv_oi: string,
        rv_usage_operateur: string,
        pbo_chambre: string,
        pbo_nomb_fibre_reel: number,
        pbo_emprise: string,
        rv_coul_tub_pm: string,
        rv_refr_rop:string,
        rv_refr_module: string,
        pbo_idnt: number,
        pbo_capacite: number,
        pbo_refr_pbo: string,
        lrc_refr_client: string,
        rv_coul_fibr_pm: string,
        rv_coul_tub_pbo: string,
        rv_refr_pbo: string,
        lrc_idnt_accs: string,
        rv_numr_fibr_tub_pbo: string,
        pbo_typ_raccordement: string,
        pbo_coordonnee_y: string,
        lrc_oc: string,
        pbo_coordonnee_x:string,
        pm_localisation: string,
        pm_cod_insee: string,
        pm_refr_pm: string,
        rv_refr_cable_pm: string,
        checked: boolean
        


}


export const displayedColumns= [ 
    'select',
    'rv_refr_cable_pbo',
        'rv_coul_fibr_pbo',
        'pm_coordonnee_y',
        'pto_idnt_accs_ftth',
        'rv_refr_pm',
        'pbo_localisation',
        'pm_coordonnee_x',
        'lrc_refr_dossier',
        'rv_position_pos',
        'rv_oi',
        'rv_usage_operateur',
        'pbo_chambre',
        'pbo_nomb_fibre_reel',
        'pbo_emprise',
        'rv_coul_tub_pm',
        'rv_refr_rop',
        'rv_refr_module',
        'pbo_idnt',
        'pbo_capacite',
        'pbo_refr_pbo',
        'lrc_refr_client',
        'rv_coul_fibr_pm',
        'rv_coul_tub_pbo',
        'rv_refr_pbo',
        'lrc_idnt_accs',
        'rv_numr_fibr_tub_pbo',
        'pbo_typ_raccordement',
        'pbo_coordonnee_y',
        'lrc_oc',
        'pbo_coordonnee_x',
        'pm_localisation',
        'pm_cod_insee',
        'pm_refr_pm',
        'rv_refr_cable_pm',
        
  
    ]