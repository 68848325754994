<div class="ipe-container">
    <div class="ipe-content row">


        <mat-card *ngFor="let ipeFile of ipeListing  ; let i=index " class="example-card col-3"  style="border-color:dimgray;"  >


          <mat-card-header >
            <mat-card-title class="title_file" >{{ipeFile['fileName'].replace("refInterne1_refInterne2_", '')}}</mat-card-title>
      
          </mat-card-header>
          <mat-card-content>
            <p class="title_file"> Taille : {{ipeFile['size'] | fileSize}}</p>
<!--
            <p class="title_file">Date  : {{ipeFile['createdOn'] | date : 'longDate'}} </p>
-->
              <p class="title_file">Date  : {{ipeFile['fileName'] | string2date | date : 'longDate'}} </p>
          </mat-card-content>
          
          <mat-card-actions>
      
            <button matTooltip="Télécharger"  mat-raised-button style="border: 0px whitesmoke;" (click)="downloadIpe(ipeFile['nodeRef'], ipeFile['fileName']) ; apiService.openSnackBar(ipeFile['fileName'], '...') ">
                <i class="material-icons">download</i>
                           </button>

      
          </mat-card-actions>

        </mat-card>
      
      
      </div>
</div>
