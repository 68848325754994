
<div class="container-fluid" >

    <div class="search">
        <mat-form-field>
            <mat-label>Recherche</mat-label>
            <input matInput (keyup)="applyFilter($event)"
                placeholder="Ex. Identifiant" #input>
        </mat-form-field>
<span style="margin-top: 1%; margin-left: 5%;">* Synchronisation des tickets en cours de développement</span>

<span style="margin-top: 1%; margin-left: 5%;">
    <a href="assets/files/dictionnaire_de_donnees.pdf" download="dictionnaire_de_donnees.pdf">
        Télécharger le dictionnaire de donnees
      </a> 
</span>

    </div>

    <div class="table-responsive" style="max-height: 60vh; overflow: scroll; zoom: 0.9;"
        tabindex="0">

        <table mat-table [dataSource]="dataSource" matSort matSortDirection
            class="mat-elevation-z2 table table-striped">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox color="warn" (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
                    </mat-checkbox> 
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox color="warn" (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)"
                        [aria-label]="checkboxLabel(element)">
                        
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="ticket_stit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white"> Identifiant </th>
                <td mat-cell *matCellDef="let row"
                    style="cursor: pointer; font-weight: bold"
                    (click)="openDialog(row)"> {{row.ticket_stit}} </td>
            </ng-container>

     
            <ng-container matColumnDef="date_creation_stit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white"> Date Ouverture </th>
                <td mat-cell *matCellDef="let row"> {{row.date_creation_stit |
                    date : 'd MMMM yyyy' }} </td>
            </ng-container>

            <ng-container matColumnDef="statut_stit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white"> Statut </th>
                <td mat-cell *matCellDef="let row"> {{row.statut_stit}} </td>
            </ng-container>

            <ng-container matColumnDef="date_cloture_stit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Date de Résolution </th>
                <td mat-cell *matCellDef="let row"> {{row.date_cloture_stit |
                    date : 'd MMMM yyyy' }} </td>
            </ng-container>

            <ng-container matColumnDef="adresse">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Adresse</th>
                <td mat-cell *matCellDef="let row">
                    {{row.adresse}} </td>
            </ng-container>

            <ng-container matColumnDef="codepostalimmeuble">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Code Postal Immeuble</th>
                <td mat-cell *matCellDef="let row"> {{row.codepostalimmeuble}} </td>
            </ng-container>

            <ng-container matColumnDef="codeinseeimmeuble">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Code Insee Immeuble</th>
                <td mat-cell *matCellDef="let row"> {{row.codeinseeimmeuble}} </td>
            </ng-container>
         
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Aucun résultat {{input.value}}
                </td>
            </tr>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true; "
                style="background-color: #e8431c;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

    </div>

    <mat-divider></mat-divider>

    <div class="action">
        <button matTooltip="Télécharger"
            style="margin-top: 20px; background-color: #e8431c"
            mat-raised-button style="border: 0px white;" (click)="exportToCsv()"
            [disabled]="selection.isEmpty()">
            <i class="material-icons">download</i>
        </button>
   <mat-paginator
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="[10, 25, 50 , 100]"
            
            >
            

        </mat-paginator>

    
    </div>

</div>



