import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.css'],
  standalone: true,
  imports: [MatCardModule, MatButtonModule, CommonModule ,MatDividerModule ],
  providers:[LoginService]
})
export class CardContainerComponent {

  constructor(protected loginService :LoginService){}


  @Output() navigateTo : EventEmitter<string> = new EventEmitter();


  navTo(e: string) {
    if(e) {
      this.navigateTo.emit(e)
    }

    

}
  cardsList = ["IPE","Tickets","ROPE"]



}
