<div class="main">
    <mat-tab-group animationDuration="200ms" color="warn" >
        <mat-tab label="Extraction IPE Quotidien" style="color: #e8431c;">
       <app-ipe-quotidien></app-ipe-quotidien>
            
        </mat-tab> 
        <mat-tab label="Archive IPE" style="color: rgb(235, 16, 16);">
            <app-ipe></app-ipe>
        </mat-tab>
        
    </mat-tab-group>

</div>


