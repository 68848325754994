
<div class="d-flex" >

	<div ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills flex-column"
		orientation="vertical" >

		<ng-container ngbNavItem="card">

			<button ngbNavLink class="nav-tabs">
				Accueil
			</button>
			<ng-template ngbNavContent>

				<app-card-container (navigateTo)="navigateTo($event)"></app-card-container>

			</ng-template>
		</ng-container>

		<ng-container ngbNavItem="ipe" class="nav-tabs">
			<button  ngbNavLink>IPE</button>
			<ng-template class="ipe" ngbNavContent>
				<app-ipe-container></app-ipe-container>
			</ng-template>
		</ng-container>

		<ng-container ngbNavItem="tickets-sav" class="nav-tabs" >
			<button ngbNavLink>SAV</button>
			<ng-template  ngbNavContent>
                <app-tickets-sav-container></app-tickets-sav-container>
			</ng-template>
		</ng-container> 


		<ng-container ngbNavItem="base_rop" class="nav-tabs">
			<button  ngbNavLink>ROP</button>
			<ng-template ngbNavContent>
				<app-base-rop></app-base-rop>
			</ng-template>
		</ng-container>

			<ng-container ngbNavItem="maia" class="nav-tabs" >
			<button ngbNavLink>MAIA</button>
			<ng-template class="maia" ngbNavContent>
                <app-maia></app-maia>
			</ng-template>
		</ng-container>  

		<ng-container ngbNavItem="carto" class="nav-tabs" >
			<button ngbNavLink style="font-size: small;">Portail SIG</button>
			<ng-template class="carto" ngbNavContent>
                <app-carto-iframe></app-carto-iframe>
			</ng-template>
		</ng-container>  

		
		<!-- <ng-container *ngIf="loginService.isAdmin()" ngbNavItem="ticket" class="nav-tabs" >
			<button ngbNavLink>Ticket</button>
			<ng-template ngbNavContent>
                <app-table-tickets></app-table-tickets>
			</ng-template>
		</ng-container>  -->


		<ng-container *ngIf="loginService.isAdmin()" ngbNavItem="admin" class="nav-tabs" >
			<button ngbNavLink>Admin</button>
			<ng-template ngbNavContent>
                <app-admin></app-admin>
			</ng-template>
		</ng-container> 

	 	

		<ng-container style="margin-top: 200px;" ngbNavItem="logout" class="nav-tabs">

			<button class="logout" (click)="logout()" routerLink="/login" ngbNavLink>
				<i class="fa fa-sign-out " 
					matTooltip="Se deconnecter"></i>

			</button>
		</ng-container>
	</div>

	<div [ngbNavOutlet]="nav" class="ms-4"></div>
</div>
