<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center">

    <mat-card class="box" >
    
      
      <form class="form-card">
        <mat-form-field>
          <mat-label>Email</mat-label>
          
          <input (keyup)="removeErrorMessage()" type="email" class="input-form"  matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher">
    
        </mat-form-field>
    
        <mat-form-field>
          <mat-label>Mot de passe</mat-label>
          
          <input (keyup)="removeErrorMessage()" type="password"   matInput [formControl]="passwordFormControl" >
    
        </mat-form-field>

        <mat-card-content>
          
            <span class=" btn btn-danger" *ngIf="!loginSucces">{{errorMessage}}</span>
        </mat-card-content>
    
        <mat-card-actions>
          <button mat-button color="primary" [disabled]="emailFormControl.invalid || passwordFormControl.invalid"
          (click)="validateLogin()"
          >Valider</button>
        </mat-card-actions>
    
    
      </form>
    
    </mat-card>
    </div>
