 <style>
     .header_tab {
         margin: 1.5px;
     }
 </style>
 <div class="ticket-container">

    <div class="searchTab">

        
            <form>
                <input class="form-control" [(ngModel)]="searchText"
                    name="searchText" type="text"
                    placeholder="Rechercher ..." />
            </form>

        

    </div>

    <table class="table-responsive table-striped ">

        <tr class="tab-content">

            <th style="position: sticky; top: 0; z-index: 1; background-color: #e8431c;color: white">
                <input type="checkbox" [checked]="isAllCheckBoxChecked()"
                    (change)="checkAllCheckBox($event)">
            </th>
            <th  *ngFor=" let header of headerTicket" scope="col" style="position: sticky; top: 0; z-index: 1; background-color: #e8431c ; color: white">

                <span class="header_tab"> {{header }}</span>

            </th>

        </tr>

        <tr *ngFor="let d of data |ngxSearchPipe :searchText ; let i=index">
  
            <td>

                <input type="checkbox" value="{{d}}"
                    [(ngModel)]="data[i].checked" (change)="onChange(data[i])">
            </td>
            <td>
                <span>

                    <a class="text"
                        (click)="openDialog(d)">{{d.identifiant_cas}}</a>

                </span>

            </td>
            <td>
                <span style="margin: 1.5px;">{{d.v_type }}</span>

            </td>
            <td>
                <span style="margin: 1.5px;">{{d.date_ouverture_cas  | date : 'd MMMM yyyy, à HH:mm' }}</span>

            </td>
            <td>
                <span style="margin: 1.5px;"
                    [ngStyle]="{'color': d.statut_cas === etat_ticket ? 'red' : '' }">{{d.statut_cas
                    }}</span>

            </td>

            <td>
                <span style="margin: 1.5px;">{{d.date_de_resolution  | date : 'd MMMM yyyy, à HH:mm'}}</span>

            </td>
            <td>
                <span style="margin: 1.5px;">{{d.numero_voie }}</span>

            </td>
            <td>
                <span style="margin: 1.5px;">{{d.type_voie }}</span>
            </td>
            <td>
                <span style="margin: 1.5px;">{{d.nom_voie}}</span>
            </td>
            <td>
                <span style="margin: 1.5px;">{{d.code_postal }}</span>
            </td>
            <td>
                <span style="margin: 1.5px;">{{d.commune }}</span>
            </td>

            <td>
                <span style="margin: 1.5px;">{{d.region }}</span>
            </td>
            <td>
                <span style="margin: 1.5px;">{{d.oc_tiers_declarant }}</span>
            </td>
            <td>
                <span style="margin: 1.5px;">{{d.oi  }}</span>
            </td>
            <td>
                <span style="margin: 1.5px;">{{d.hexacle }}</span>
            </td>
            <td>
                <span style="margin: 1.5px;">{{d.prise_client_impactee
                    }}</span>
            </td>
            <td>
                <span style="margin: 1.5px;">{{d.reference_pm}}</span>
            </td>
            <td>
                <span style="margin: 1.5px;">{{d.ref_pbo }}</span>
            </td>
        </tr>
        
    </table>
  <div class="pagination">
   

        <button mat-fab [disabled]="selectedTickets.length===0"
            (click)="exportToCsv()">
            <i
                class="material-icons">download</i>
        </button>

   
<!--    <app-pagination  #pagination (_nextPage)="pageSelected($event)"   [collectionSize]=13500 [pageSize]=ticketPerPage [firstLastButtons]="true" [maxSize]="20" ></app-pagination>-->
     <mat-paginator #paginator
             (page)="handlePageEvent($event)"
                    [showFirstLastButtons]="showFirstLastButtons"
             [length]="length"
             [pageSize]="pageSize"
             [disabled]="false"
             [pageIndex]="pageIndex"
     [pageSizeOptions]="pageSizeOptions"
     aria-label="Select page"

     >


     </mat-paginator>

  </div> 

   

</div>
 

 