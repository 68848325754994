import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { LoginService } from 'src/app/services/login.service';


export interface DspUsers {
  firstName: string;
  LastName: string;
  Role: string;
  
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit{

  displayedColumns: string[] = ['firstName', 'LastName', 'Role', 'update'];
  dataSource : any;
  userDetails: any;
  constructor( private adminService :AdminService, private login : LoginService){}


  ngOnInit(): void {

    this.userDetails =JSON.parse(this.login.getUser() || '').user;
    
   const id = this.userDetails.operateursInfra[0].idOi;
     
    this.getUsers(id)

  }

  getUsers(idDsp : string){

    this.adminService.getAllUser(idDsp)
  }

}
