<div class="container-fluid">

<iframe [src]="url"
 width="100%"
 height="700px"
  frameborder="0" 
  allowfullscreen
>

</iframe>
</div>
