
<div class="container-fluid" >

    <div class="search">
        <mat-form-field>
            <mat-label>Recherche</mat-label>
            <input matInput (keyup)="applyFilter($event)"
                placeholder="Ex. Identifiant" #input>
        </mat-form-field>

    </div>

    <div class="table-responsive" style="max-height: 60vh; overflow: scroll"
        tabindex="0">

        <table mat-table [dataSource]="dataSource" matSort matSortDirection
            class="mat-elevation-z2 table table-striped">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox color="warn" (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox color="warn" (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)"
                        [aria-label]="checkboxLabel(element)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="identifiant_cas">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white"> Identifiant </th>
                <td mat-cell *matCellDef="let row"
                    style="cursor: pointer; font-weight: bold"
                    (click)="openDialog(row)"> {{row.identifiant_cas}} </td>
            </ng-container>

            <!--   <ng-container matColumnDef="v_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white"> Type </th>
            <td mat-cell *matCellDef="let row"> {{row.v_type}} </td>
        </ng-container> -->
            <ng-container matColumnDef="date_ouverture_cas">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white"> Date Ouverture </th>
                <td mat-cell *matCellDef="let row"> {{row.date_ouverture_cas |
                    date : 'd MMMM yyyy' }} </td>
            </ng-container>

            <ng-container matColumnDef="statut_cas">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white"> Statut </th>
                <td mat-cell *matCellDef="let row"> {{row.statut_cas}} </td>
            </ng-container>

            <ng-container matColumnDef="oc_tiers_declarant">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white"> OC tiers déclarant </th>
                <td mat-cell *matCellDef="let row"> {{row.oc_tiers_declarant}}
                </td>
            </ng-container>
            <ng-container matColumnDef="date_de_resolution">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Date de Résolution </th>
                <td mat-cell *matCellDef="let row"> {{row.date_de_resolution |
                    date : 'd MMMM yyyy' }} </td>
            </ng-container>

            <ng-container matColumnDef="raison_sociale_client">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Raison Sociale Client</th>
                <td mat-cell *matCellDef="let row">
                    {{row.raison_sociale_client}} </td>
            </ng-container>

            <ng-container matColumnDef="numero_voie">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Numéro de voie</th>
                <td mat-cell *matCellDef="let row"> {{row.numero_voie}} </td>
            </ng-container>

            <ng-container matColumnDef="type_voie">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Type de voie</th>
                <td mat-cell *matCellDef="let row"> {{row.type_voie}} </td>
            </ng-container>

            <ng-container matColumnDef="nom_voie">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Voie</th>
                <td mat-cell *matCellDef="let row"> {{row.nom_voie}} </td>
            </ng-container>

            <ng-container matColumnDef="code_postal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Code Postal</th>
                <td mat-cell *matCellDef="let row"> {{row.code_postal}} </td>
            </ng-container>

            <ng-container matColumnDef="commune">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Commune</th>
                <td mat-cell *matCellDef="let row"> {{row.commune}} </td>
            </ng-container>
            <ng-container matColumnDef="region">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="color: white">Region</th>
                <td mat-cell *matCellDef="let row"> {{row.region}} </td>
            </ng-container>
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Aucun résultat {{input.value}}
                </td>
            </tr>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true; "
                style="background-color: #e8431c;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

    </div>

    <mat-divider></mat-divider>

    <div class="action">
        <button matTooltip="Télécharger"
            style="margin-top: 20px; background-color: #e8431c"
            mat-raised-button style="border: 0px white;" (click)="exportToCsv()"
            [disabled]="selection.isEmpty()">
            <i class="material-icons">download</i>
        </button>
        <mat-paginator
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="[10, 25, 50 , 100]">

        </mat-paginator>
    </div>

</div>


