<div class="template-container">
    <div class="template-content row">

        <mat-card *ngFor="let d of data  ; let i=index " class="example-card col-3"   >

          <mat-card-header >
            <mat-card-title class="title_file" >{{d['fileName'].replace("refInterne1_refInterne2_", '')}}</mat-card-title>
      
          </mat-card-header>
          <mat-card-content>
            <p class="title_file"> Taille : {{d['size'] | fileSize}}</p>
<!--
            <p class="title_file">Date  : {{templateFile['createdOn'] | date : 'longDate'}} </p>
-->
              <p class="title_file">Date  : {{d['fileName'] | string2date | date : 'longDate'}} </p>
          </mat-card-content>
          
          <mat-card-actions>
      
            <button matTooltip="Télécharger"  mat-raised-button style="border: 0px whitesmoke;" (click)="download(d['nodeRef'], d['fileName']) ; apiService.openSnackBar(d['fileName'], '...') ">
                <i class="material-icons">download</i>
                           </button>
      
          </mat-card-actions>

        </mat-card>
      
      
      </div>
</div>
