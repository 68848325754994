import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import {TableTicketsComponent} from "../table-tickets/table-tickets.component";


@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.css']

})
export class MainContainerComponent implements OnInit {

  
  // @ts-ignore
  constructor(protected loginService: LoginService){}
  ngOnInit(): void {
    this.loginService.currentUserSubject.subscribe(
     resp => {
      //console.log(resp.user.authorities)
      this.loginService.currentUser=resp.user
     } 
     
    )


 
  
    
  }

navigateTo($event: string) {

  this.active= $event
  
}
  active = 'card';
  activeId=true;


  logout() {
    this.loginService.logout();
    
    }
  
}
