
<div class="ipe-container-preview">

    <div>Previsualisation du fichier ROP max 10 lignes</div>


  <table fxFlex="60" style="padding: 2%;">
    
      <tr>
          <th *ngFor=" let header of data.headers" scope="col">
              <span style="margin: 1.5px;"> {{header.replace('""', '').replace('""','')}}</span>

          </th>
      </tr>
      <tr *ngFor="let line of data.lines.slice(1,10)">
          <td *ngFor="let l of line.split(';')">
              <span style="margin: 1.5px;">{{l}}</span>
                 
          </td>
      </tr>

  </table>

</div>