import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { AlfrescoFileModel } from 'src/app/models/alfrescoNode';
import { ApiService } from 'src/app/services/api.service';
import {MatSnackBar} from "@angular/material/snack-bar";



@Component({
  selector: 'app-ipe-quotidien',
  templateUrl: './ipe-quotidien.component.html',
  styleUrls: ['./ipe-quotidien.component.css']
})
export class IpeQuotidienComponent implements OnInit , AfterViewInit {

constructor(protected apiService : ApiService ) {}

    color = 'primary';
    mode = 'determinate';
    value = 50;
    bufferValue = 75;

ipeListing : AlfrescoFileModel[]= [];



  ngOnInit(): void {

      this.IpeQuotidien();


  }
    ngAfterViewInit(): void {



    }



  IpeQuotidien() : AlfrescoFileModel[] {
      this.apiService.getFolderListAlfrescoQuotidien().subscribe(
          (ipes ) => {
              this.ipeListing= ipes.items;
              this.ipeListing =this.ipeListing.reverse();
              //this.ipeListing=this.ipeListing.sort((a,b)=> {return  this.checkIpeDate(a).getUTCDate() - this.checkIpeDate(b).getUTCDate()})
              let date =
                  this.ipeListing[0].fileName.replace("refInterne1_refInterne2_THDD_PM_IPEZMD_",'')
                      .slice(4).replace('_1.csv', '');
              let myDate=new Date();
              myDate.setUTCFullYear(Number(date.substring(0,4)), Number(date.substring(4,6) )-1,Number(date.substring(6,8)))


          }
      )
      return this.ipeListing;

  }




  checkIpeDate(ipe:AlfrescoFileModel){
            let _date =ipe.fileName.replace("refInterne1_refInterne2_THDD_PM_IPEZMD_",'')
              .slice(4).replace('_1.csv', '');
      let myDate=new Date();
      myDate.setUTCFullYear(Number(_date.substring(0,4)), Number(_date.substring(4,6) )-1,Number(_date.substring(6,8)))

      return myDate ;
  }



  downloadIpe(workspace: string, filename :string) {
    let node='';
     node=workspace.replace("workspace://SpacesStore/", '');
     this.apiService.getFileFromAlfresco(node, filename)
     
     }


}