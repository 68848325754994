import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/api.service';
import { LoginComponent } from '../login/login.component';
import { AlfrescoFileModel } from 'src/app/models/alfrescoNode';



@Component({
  selector: 'app-ipe',
  templateUrl: './ipe.component.html',
  styleUrls: ['./ipe.component.css']
})
export class IpeComponent implements OnInit {



searchText: string="";
  fileUrl: any;
  loading: boolean = false;
ticketPerPage: number=9;
  
  constructor(protected apiService : ApiService, private sanitizer: DomSanitizer){}
  headers: string[] = [];
  lines: string[] = []; 
  errorMessage: string= '';
  size : number = 107000;
  ipeListing : AlfrescoFileModel[]= []

  ngOnInit(): void {

    this.apiService.getFolderListAlfresco().subscribe(
      (ipes ) => {

        this.ipeListing= ipes.items;
        this.ipeListing=this.ipeListing.sort((a,b)=> {return  new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()})

      }
    );


   

    // Poc local file ipe for thdd
  /*   this.apiService.getFolderListAlfrescoLocal().subscribe(
      list => {
      this.ipeListing = list['items'];
      this.ipeListing=this.ipeListing.sort((a,b)=> {return  new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()})
      
      if(list['items'].length>0) console.log(this.ipeListing.length);
      
       
      }
    ); */
    

   // this.apiService.getDatasFromAlfresco();


    const data = "assets/files/refInterne1_refInterne2_THDD_PM_IPEZMD_V31_20240604_1.csv";
    const blob = new Blob([data], { type: 'application/octet-stream' });

    

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    
  }

 
 

  downloadIpe(workspace: string, filename :string) {
   let node='';
    node=workspace.replace("workspace://SpacesStore/", '');
    this.apiService.getFileFromAlfresco(node, filename);
    
    }

   /* download() {
    this.apiService.downloadCSV(URLdataIpe).subscribe(
      (res : any) => {
        let dataType = res.type;
        let binaryData = [];
        binaryData.push(res);
       
        
        let downloadLink = document.createElement('a');
        this.loading= true;
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        let filename : string = 'refInterne1_refInterne2_THDD_PM_IPEZMD_V31_20240604_1.csv'
        if (filename)
          downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click()
      },
      (error : Error) => {
        this.loading= false;
          this.errorMessage = error.message;
        
      },
      ()=> {

        this.loading= false
      }
      
    )
  } 
 */
}
