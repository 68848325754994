import { HttpBackend, HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TicketsSav } from '../models/ticketModel';
import { Observable, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IpeComponent } from "../components/ipe/ipe.component";
import { IpeQuotidienComponent } from "../components/ipe-quotidien/ipe-quotidien.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessagesComponent } from "../components/messages/messages.component";
import { BaseRop } from '../models/baseRop';
import { AlfrescoFileModel } from '../models/alfrescoNode';




@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public loading: boolean | undefined;
  tableData: any = [];
  public ipeQuotidienComponent: IpeQuotidienComponent | undefined

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {}


  downloadCSV(url: string): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.get<any>(url, { headers, responseType: 'blob' as 'json' })

  }

  getFileFromAlfresco(nodeId: string, filename: string) {
      return this.http.get('/backend/alfresco/ipe/download/' + nodeId, { responseType: 'blob' as 'json' }).pipe(
      tap((data) => {
        console.log(data)
        this.loading = true;
        console.log(this.loading)
      })
    )

      .subscribe(
        (response: any) => {

          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          if (filename)
            downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        (error) => {
          console.error("Erreur de telechargement", error);
        },
        () => {
          // @ts-ignore
          this._snackBar.dismiss();
        }
      )
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.openFromComponent(MessagesComponent, {
      data: {
        message: "Téléchargement du fichier " + message + " en cours ....",
      },
      verticalPosition: "top",
      panelClass: ["snackBar"]
    });
  }

  getFolderListAlfresco() {
    return this.http.get<any>('/backend/alfresco/ipe/archive');
  }


  getFolderAlfrescoBaseRop() {
    return this.http.get<any>('/backend/baserop/extraction');
  }

  getFolderListAlfrescoQuotidien() {
    return this.http.get<any>('/backend/alfresco/ipe/quotidien');
  }


  getFolderListAlfrescoMaia() {
    return this.http.get<any>('/backend/alfresco/maia/quotidien');
  }

  getFolderListArchiveMaia() {
    return this.http.get<any>('/backend/alfresco/maia/archive')
  }


}
