import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserModel } from '../models/UserModel';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Credentials } from '../models/Credentials';
import {UserInfo} from "../models/userInfoModel";
import { jwtDecode } from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class LoginService {



  
  isConnected: boolean = false;
  activateModules : string []=[];

  public currentUserSubject: BehaviorSubject<any> ;
  public currentUser: Observable<UserInfo>;
 

  constructor( private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('currentUser')|| '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

    Login(user :Credentials) {
   
       return this.http.post<any>('backend/api/auth/authenticate', user )
    }

    register(user : UserModel) {
      return this.http.post<UserModel>('backend/api/auth/register', user )
    }

    getLoginByUsername(username:string){

      return this.http.get<UserModel>('backend/api/auth/'+username);

    }

    getCurrentValue(): UserInfo{
      return this.currentUserSubject.value;
    }

    getActivatedModuleForDSP(){
      
    this.activateModules=  this.currentUserSubject.getValue().user.operateurs[0].modules;
      return this.activateModules;
    }

    getQuadrigramme(){
     return this.currentUserSubject.getValue().user.operateurs[0].quadrigramme;
    }

    logout() {
      // supprimer les détails de l'utilisateur du local storage pour déconnecter l'utilisateur
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      this.currentUserSubject.next(this.currentUser);
  }

  saveToken( token: string) {

    localStorage.setItem('token', token);
  }



  getToken() {
    if(localStorage.getItem('token') != null) {
      return localStorage.getItem('token');
    } else {

      return null;
    }
  }


  getUser() {
    if(localStorage.getItem('currentUser') != null) {
      return localStorage.getItem('currentUser');
    } else {

      return null;
    }


    
   

    
  }

  

  isAuthenticated(): boolean {
    // Vous pouvez vérifier ici la présence d'un token ou d'une session
    const token = localStorage.getItem('token');
    return !!token;
  }


    
  getRoleFromToken(): string | null {
    const token = this.getToken();
    if (token) {
      const decoded: any = jwtDecode(token);
      //console.log(decoded);
      
      return decoded.authorities || null;  // Supposons que le rôle est stocké dans la propriété "role" du token
    }
    return null;
  }

  isAdmin(): boolean{

    const role = this.getRoleFromToken();
    //console.log(role)
    return !!role?.includes('ADMIN')

  }
  
}
