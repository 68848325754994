import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TicketsSav } from '../models/ticketModel';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  constructor(private http : HttpClient ,handler : HttpBackend ) { 
   // this.http = new HttpClient(handler);
  }


    getAllTicketsFromReferPlus( dsp : string){
 
        return this.http.get<any>(`/backend/refer/sav/ticketing/${dsp}`);
        
    }


    getAllTicketsSavTiersFromReferPlus( typeOc : string ,  page : number , size : number ){
 
      return this.http.get<any>(`/backend/refer/sav/ticketingtiers/${typeOc}/${page}/${size}`);
      
  }

  getNumberOfData(typeOc : string){


    return this.http.get<any>(`/backend/refer/countElement/${typeOc}`)

  }

  getLocalTicketsSav(vue : string){

    return this.http.get<any>(`assets/files/${vue}.json`)

  }


}
