<div class="ipe-container">
    <div class="ipe-content row">


        <mat-card *ngFor="let file of fileListing  ; let i=index " class="example-card col-3"  style="border-color:dimgray;"  >


          <mat-card-header >
            <mat-card-title class="title_file" >{{file['fileName'] | uppercase}}</mat-card-title>
      
          </mat-card-header>
          <mat-card-content>
            <p class="title_file"> Taille : {{file['size'] | fileSize}}</p>

            <p class="title_file">Date  : {{file['createdOn'] | date : 'longDate'}} </p>

              <!-- <p class="title_file">Date  : {{file['fileName'] | string2date | date : 'longDate'}} </p> -->
          </mat-card-content>
          
          <mat-card-actions>
      
            <button matTooltip="Télécharger"  mat-raised-button style="border: 0px whitesmoke;" (click)="downloadIpe(file['nodeRef'], file['fileName']) ; apiService.openSnackBar(file['fileName'], '...') ">
                <i class="material-icons">download</i>
                           </button>

      
          </mat-card-actions>

        </mat-card>
      
      
      </div>
</div>
