import { MatPaginatorIntl } from '@angular/material/paginator';

export class CustomPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();

    // Remplace le texte "Items per page"
    this.itemsPerPageLabel = 'Tickets par page:';
    this.nextPageLabel='page suivante';
    this.previousPageLabel= 'page précédente';
    this.lastPageLabel='dernière page'
    this.firstPageLabel='première page'
    
  }

  // Tu peux également personnaliser d'autres libellés ici si nécessaire
  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 sur ${length}`;
    }
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} sur ${length}`;
  };
}
