import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'string2date'
})
export class String2datePipe implements PipeTransform {

  transform(value: string, ...args: string[]): Date {

    let date =
        value.replace("refInterne1_refInterne2_THDD_PM_IPEZMD_",'')
            .slice(4).replace('_1.csv', '');
    let myDate=new Date();
    myDate.setUTCFullYear(Number(date.substring(0,4)), Number(date.substring(4,6) )-1,Number(date.substring(6,8)))




    return myDate;
  }

}
