import {DatePipe, LowerCasePipe, NgForOf, NgIf} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TicketsSav } from 'src/app/models/ticketModel';
import { FontsPipe } from 'src/app/pipes/fonts.pipe';
import {MatListModule} from "@angular/material/list";
import {AppModule} from "../../app.module";
import {codeErreurTickets, CodeErreurTickets} from "../../models/code-erreur-tickets";
import {MatExpansionModule, MatExpansionPanel} from "@angular/material/expansion";
import {MatTooltipModule} from "@angular/material/tooltip";
import { LoginService } from 'src/app/services/login.service';
import { TicketsOcTierModel } from 'src/app/models/ticketsOctiersModels';
import { TicketsOcSfrModel } from 'src/app/models/ticketsOcSfrModels';
import { Dialog } from '@angular/cdk/dialog';
import { DictionnaireComponent } from '../dictionnaire/dictionnaire.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-detail-ticket',
  templateUrl: './detail-ticket.component.html',
  styleUrls: ['./detail-ticket.component.css'],
  standalone: true,
  providers:[LoginService],
  imports: [MatDialogModule, MatButtonModule, DatePipe, MatListModule, MatExpansionModule, NgForOf, LowerCasePipe, NgIf, MatTooltipModule]
})
export class DetailTicketComponent implements OnInit{

  pdfSrc="assets/files/DDD.pdf"

  safePdfurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc);

  codeErreurTickets : CodeErreurTickets[] = codeErreurTickets;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TicketsOcTierModel & TicketsOcSfrModel , protected loginService :LoginService , private dialog: Dialog ,private sanitizer: DomSanitizer){};

  ngOnInit(): void {

    // console.table(this.codeErreurTickets)
  }

  openDialog() {
    this.dialog.open(DictionnaireComponent,
      
    
    )

  }

 


}
