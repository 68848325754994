import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoginService } from 'src/app/services/login.service';
import { TicketService } from 'src/app/services/ticket.service';
import { MessagesComponent } from '../messages/messages.component';
import { TicketsOcSfrModel } from 'src/app/models/ticketsOcSfrModels';
import { DetailTicketComponent } from '../detail-ticket/detail-ticket.component';
import { catchError, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-tickets-sav-oc-sfr',
  templateUrl: './tickets-sav-oc-sfr.component.html',
  styleUrls: ['./tickets-sav-oc-sfr.component.css']
})
export class TicketsSavOcSfrComponent implements OnInit, OnDestroy {

  selection = new SelectionModel<TicketsOcSfrModel>(true, []);
  showFirstLastButtons = true;
  isLoadingResults = true;
  nb = "Nombre de tickets par page";
  page = 1;
  size = 10;

 

  @Input() totalItems!: number
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: MatTableDataSource<TicketsOcSfrModel>;
  filterValues: any;
  columns = ["ticket_stit","statut_stit","date_creation_stit","date_cloture_stit","diag_en_cours_stit","code_de_cloture","diagnostic_stit","commentaire_resolution_stit","idnt_accs_ftth","lrc_idnt_immeuble","pbo_refr_pbo","element_impacte","adresse","codepostalimmeuble","codeinseeimmeuble","communeimmeuble"];
  unsubscribe$= new Subject<void>();

  constructor(private dialog: MatDialog, private ticketService: TicketService, private _snackBar: MatSnackBar, protected loginService: LoginService) {
    this.dataSource = new MatTableDataSource();
  }


  displayedColumns = [
    'select',
    'ticket_stit',
    'statut_stit',
    'date_creation_stit',
    'date_cloture_stit',
    'adresse',
    'codeinseeimmeuble',
    'codepostalimmeuble'
  ]


  ngOnInit(): void {

   // this.getDataFromApi();
   this.getLocalTicketSav();



  }


  displayMessage(message: String, _class: string[]) {
    this._snackBar.openFromComponent(MessagesComponent, {

      data: {
        message: message
      },
      verticalPosition: "bottom",
      panelClass: _class,

    })

  }

  isAllSelected() {


    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {


    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);


  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TicketsOcSfrModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    // @ts-ignore
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.ticket_stit + 1}`;
  }



  getColumns(data: any[]): string[] {
    const columns: string[] = [];
    data.forEach(row => {
      Object.keys(row).forEach(col => {
        if (!columns.includes(col)) {
          columns.push(col);
        }


      });
    });

    return columns;
  }


  onPageChange(event: any) {
    this.page = event.pageIndex + 1;
    this.size = event.pageSize;
    this.getDataFromApi();

  }
  getDataFromApi() {
      this.ticketService.getAllTicketsSavTiersFromReferPlus('vue_sav_oc_sfr_thdd', this.page, this.size)
      .pipe(
        catchError((error)=> {
          let message ='Erreur lors de la récupération des tickets';
          console.log("Erreur lors de la récupération des tickets");
          this.displayMessage(message, ['snackBarWarn']);
          setTimeout(()=> {
           this._snackBar.dismiss()
         }, 3000);
          return of([])
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res :TicketsOcSfrModel[]) => {
        this.dataSource = new MatTableDataSource(res);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.start = 'desc';
   // this.dataSource.paginator = this.paginator;
      })
  }

  getLocalTicketSav(){

    this.ticketService.getLocalTicketsSav('vue_sav_oc_sfr_thdd').subscribe(
      res => {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        this.dataSource.sort.start = 'desc';
        this.dataSource.paginator = this.paginator;
      }
      
    )
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: TicketsOcSfrModel, filter: string) => {
      const ticket_stit = data.ticket_stit ? data.ticket_stit.toLowerCase() : '';
      const date_creation_stit = data.date_creation_stit ? data.date_creation_stit : '';
      const statut_stit = data.statut_stit ? data.statut_stit.toLowerCase() : '';
      const date_cloture_stit = data.date_cloture_stit ? data.date_cloture_stit : '';
      const adresse = data.adresse ? data.adresse.toLowerCase() : '';
      const codepostalimmeuble = data.codepostalimmeuble ? data.codepostalimmeuble.toLowerCase() : '';
      const codeinseeimmeuble = data.codeinseeimmeuble ? data.codeinseeimmeuble.toLowerCase() : '';
      const communeimmeuble = data.communeimmeuble ? data.communeimmeuble.toLowerCase() : '';
      const dataStr = `${ticket_stit} ${date_creation_stit} ${statut_stit}  ${date_cloture_stit} ${adresse} ${codepostalimmeuble} ${codeinseeimmeuble} ${communeimmeuble} `
      return dataStr.indexOf(filter) !== -1;
    };
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  openDialog(data: any) {
    this.dialog.open(DetailTicketComponent,
      {
        data: data

      }
    )

  }

  convertToCsv(data: any[], columns: string[]): string {
    let encoding = 'ANSI';
    let csv = '';
    csv += columns.join(';') + '\n';
    data.forEach(row => {

      const values: any[] = [];
      columns.forEach(col => {
        values.push(row[col] || '');
      });
      csv += values.join(';') + '\n';
    });
    return csv;
  }

  exportToCsv() {

    const data = this.selection.selected.map(item => {

      return {
        ...item,

      }
    });
      console.log(data);
      const csvData = this.convertToCsv(data, this.columns);
      this.downloadSelectedTickets(csvData, `Tickets-OC-SFR-${new Date().toLocaleDateString()}.csv`, 'text/csv;charset=utf-8;');
  }

  downloadSelectedTickets(data: string, filename: string, type: string) {
    const blob = new Blob([`\uFEFF${data}`], {type:type}  );
    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  ngOnDestroy(): void {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();

  }

}
