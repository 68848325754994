import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  standalone: true,
  styleUrls: ['./messages.component.css'],
  providers: [MatSnackBarModule, MatProgressBarModule],
  imports: [
    MatProgressBarModule
  ]
})
export class MessagesComponent {

  constructor(private snackBar: MatSnackBar ,@Inject(MAT_SNACK_BAR_DATA) public data:any) { }


}
