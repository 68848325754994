import {AfterViewInit, Component, Inject, OnInit, ViewChild,ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {TicketService} from "../../services/ticket.service";
import {MatPaginator, MatPaginatorModule, PageEvent} from "@angular/material/paginator";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {Ticket, TicketsSav, TicketsSavColumn} from "../../models/ticketModel";
import {SelectionModel} from "@angular/cdk/collections";
import {forEach, indexOf} from "lodash";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DetailTicketComponent} from "../detail-ticket/detail-ticket.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessagesComponent} from "../messages/messages.component";
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent,DateRange } from '@angular/material/datepicker';
import { LoginService } from 'src/app/services/login.service';

export interface filterObj  {
      name: string,
      columnProp: string,
      options: []
}

@Component({
  selector: 'app-table-tickets',
  templateUrl: './table-tickets.component.html',
  styleUrls: ['./table-tickets.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTicketsComponent implements OnInit, OnDestroy{

  dsp = "THDD";
  selection = new SelectionModel<TicketsSav>(true, []);
  showFirstLastButtons = true;
  isLoadingResults = true;
  nb="Nombre de tickets par page";

  displayedColumns= [ 
  'select',
  'identifiant_cas',
  'date_ouverture_cas',
  'statut_cas',
  'oc_tiers_declarant',
  'date_de_resolution',
  'raison_sociale_client',
  'numero_voie',
  'type_voie',
  'nom_voie',
  'code_postal',
  'commune',
  'region',

  ]

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: MatTableDataSource<TicketsSav>;
  filterValues: any;
  

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });


  constructor(private dialog: MatDialog , private ticketService :TicketService ,private _snackBar: MatSnackBar, protected loginService : LoginService) {
    this.dataSource = new MatTableDataSource();
 
  
  }
 

  ngOnInit(): void {

    this.loginService.currentUserSubject.getValue();
      console.log(this.loginService.currentUserSubject.getValue());
      
   //this.dsp= this.loginService.currentUserSubject.getValue()['user']['operateurs'][0]['quadrigramme'];
    
    
    

    
      let message ="Chargement des Tickets en cours ...."
      this.displayMessage(message, ['snackBar']);
    
    

  
    

    this.ticketService.getAllTicketsFromReferPlus(this.dsp).subscribe(
        (tickets : TicketsSav[]) => {

          console.log(tickets);
          
          this.dataSource = new MatTableDataSource(tickets);
            this.dataSource.sort = this.sort;
          this.dataSource.sort.start="desc";
          console.log(this.dataSource.sort);
          
            this.dataSource.paginator= this.paginator;


        },
        error => {
          console.log(error);
          this.isLoadingResults = false;
          let message ="Impossible de charger les Tickets merci de contacter l'administrateur."
          this.displayMessage(message, ['snackBarWarn']);
          setTimeout(()=> {
            this._snackBar.dismiss()
          }, 3000)
          ;
        },
        () => {
          this._snackBar.dismiss();
        }
    )

   
    
    
  }





  
    dateEvent(type: string,$event: MatDatepickerInputEvent<any,DateRange<any>>) {
      this.dataSource.filterPredicate=this.customFilterPredicate();
      
      if(type === 'start'){
         let date = $event.target.value;
         let newdate=new Date(date).toISOString();
       
         const dateFilter = $event.target.value ? $event.target.value.toISOString().split('T')[0] : '';
       
         
      }

      if(type === 'end'){
      
        const dateFilter = $event.target.value ? $event.target.value.toISOString().split('T')[0] : '';
      
        
        this.dataSource.filter = dateFilter;
      }
    }

    
    customFilterPredicate() {
      return (data: any, filter: string): boolean => {
        const debutString = new Date(data.date_ouverture_cas).toISOString().split('T')[0] 
        const finString = new Date(data.date_de_resolution).toISOString().split('T')[0] 
        return debutString >= filter || finString <= filter ;
      };
    }
  
   


  applyFilter(event: Event) {

  
    
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
  
    
    
   
    this.dataSource.filterPredicate=(data : TicketsSav, filter : string) => {
     

      const v_type = data.v_type ? data.v_type.toLowerCase() : '' ;
      const commune = data.commune ? data.commune.toLowerCase() : '' ;
      const oc = data.oc_tiers_declarant ? data.oc_tiers_declarant.toLowerCase() : '' ;
      const statut_cas = data.statut_cas ? data.statut_cas.toLowerCase() : '' ;
      const cp = data.code_postal ? data.code_postal.toLowerCase() : '' ;
      const identifiant_cas = data.identifiant_cas ? data.identifiant_cas.toLowerCase() : '' ;
      const raison_sociale_client = data.raison_sociale_client ? data.raison_sociale_client.toLowerCase(): '';
      const type_voie = data.type_voie ? data.type_voie.toLowerCase() : '';
      const nom_voie = data.nom_voie ? data.nom_voie.toLowerCase() : '';
      const numero_voie = data.numero_voie ? data.numero_voie.toLowerCase() : '';
      // const date_de_resolution = data.date_de_resolution ? data.date_de_resolution : '';
     

      const dataStr =`${commune} ${oc} ${statut_cas} ${cp} ${identifiant_cas} ${raison_sociale_client} ${v_type} ${type_voie} ${nom_voie} ${numero_voie} `

      return dataStr.indexOf(filter) !== -1;

    };

    this.dataSource.filter = filterValue;
    
     if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    } 
  }

 

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    
    
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
   
      this.selection.select(...this.dataSource.filteredData);
 
    
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TicketsSav): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    // @ts-ignore
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.identifiant_cas + 1}`;
  }


  openDialog(data : any) {
    this.dialog.open(DetailTicketComponent,
        {
          data: data
         
        }
    )
  }


  getColumns(data: any[]): string[] {
    const columns: string[] = [];
    data.forEach(row => {
      Object.keys(row).forEach(col => {
        if (!columns.includes(col)) {
          columns.push(col);
        }

        
      });
    });

    return columns;
  }


  // /!\ Convert csv séparateur point virgule
  convertToCsv(data: any[], columns: string[]): string {
    let encoding = 'ANSI';
    let csv = '';
    csv += columns.join(';') + '\n';
    data.forEach(row => {
      
      const values: any[] = [];
      columns.forEach(col => {
        values.push(row[col] || '');
      });
      csv += values.join(';') + '\n';
    });
    return csv;
  }


  exportToCsv() {

   const data = this.selection.selected.map(item => {      
            
      return { ...item, 
        date_ouverture_cas : item.date_ouverture_cas.toString().split('T')[0], heure_ouverture_cas: item.date_ouverture_cas.toString().split('T')[1].split('.000+00:00')[0],
        date_de_resolution : item.date_de_resolution?.toString().split('T')[0] , heure_de_resolution: item.date_de_resolution?.toString().split('T')[1].split('.000+00:00')[0] ,
       // date_ouverture_intrv:item.date_ouverture_intrv?.toString().split('T')[0], heure_ouverture_intrv:item.date_ouverture_intrv?.toString().split('T')[1].split('.000+00:00')[0],
        //date_previsionnelle_intrv:item.date_previsionnelle_intrv?.toString().split('T')[0], heure_previsionnelle_intrv:item.date_previsionnelle_intrv?.toString().split('T')[1].split('.000+00:00')[0],
        date_actualisation:item.date_actualisation?.toString().split('T')[0],heure_actualisation:item.date_actualisation?.toString().split('T')[1].split('.')[0],
        //date_de_fermeture_intrv : item.date_de_fermeture_intrv?.toString().split('T')[0], heure_fermeture_intrv:item.date_de_fermeture_intrv?.toString().split('T')[1].split('.000+00:00')[0],
        date_traitement : item.date_traitement?.toString().split('T')[0], heure_traitement : item.date_traitement?.toString().split('T')[1].split('.000+00:00')[0],
        date_de_derniere_modification :item.date_de_derniere_modification?.toString().split('T')[0],heure_derniere_modification:item.date_de_derniere_modification?.toString().split('T')[1].split('.000+00:00')[0],
        date_de_derniere_mise_ajour:item.date_de_derniere_mise_ajour?.toString().split('T')[0],heure_derniere_mise_ajour:item.date_de_derniere_mise_ajour?.toString().split('T')[1].split('.000+00:00')[0]
      }
    });


    const columns = [
      "identifiant_cas",
      "statut_cas",
      "v_type",
      "date_ouverture_cas",
      "heure_ouverture_cas",
      "numero_compte_client",
      "raison_sociale_client",
      "type_voie",
      "numero_voie",
      "nom_voie",  
      "code_postal",
      "commune",
      "region",
      "prise_client_impactee",
      "id",
      "oc_tiers_declarant",
      "ref_pbo",
      "reference_pm",
      "date_de_resolution",
      "heure_de_resolution",
      "diagnostic",
      "commentaire", 
    
  ]

    const csvData = this.convertToCsv(data, columns);
    this.downloadSelectedTickets(csvData, `Tickets-${new Date().toLocaleDateString()}.csv`, 'text/csv;charset=utf-8;');
  }

  downloadSelectedTickets(data: string, filename: string, type: string) {
    const blob = new Blob([`\uFEFF${data}`], {type:type}  );
    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  displayMessage( message : String, _class : string[]){
    this._snackBar.openFromComponent(MessagesComponent, {

      data : {
        message :message
      },
      verticalPosition : "bottom",
      panelClass:_class,

    })

  }


  ngOnDestroy(): void {
    this._snackBar.dismiss();
  }



}
