<div class="container">
    <div class="header">
        <h4><strong>Detail du ticket</strong>
          <span>
            {{data.numero_cas}}  {{data.ticket_stit}}
          </span>

                
           
            </h4>
        <img src="assets/img/logos/THDD.png" width="120px" alt>
    </div>
    <mat-divider></mat-divider>
    <br>
    <p> <i class="fa fa-home" style="color: #e8431c"> </i> <span
       > {{data.adresse}}    </span> </p>
       <p>  <strong>Code insee immeuble : </strong>  {{data.codeinseeimmeuble}}</p>
       
    <div>
        <p *ngIf="data.element_impacte"> 
            <strong >Element Impacté : </strong> {{data.element_impacte}}</p>
         <p>
          <strong>Référence PBO : </strong>  {{data.pbo_refr_pbo}}
         </p>
             
       
    </div>
   
    <mat-divider></mat-divider>
   
    <strong>Plage de date Ouverture :</strong> {{data.date_creation_cas| date :
    'd MMMM yyyy, à HH:mm' }}   {{data.date_creation_stit| date :
        'd MMMM yyyy, à HH:mm' }} 
    <br>
    <p><strong>Statut Cas : </strong><span>{{data.statut_du_cas }} {{data.statut_stit }}</span> </p>
    <br>

    <mat-divider></mat-divider>
    <p><strong>OC:</strong> {{data.nom_oc}} {{data.oc ? data.oc.toUpperCase() :'' }} </p>
    <p> <strong>Identifiant ftth :</strong> {{data.idnt_accs_ftth}} </p>
    <p> <strong>Identifiant Immeuble :</strong> {{data.lrc_idnt_immeuble}}  {{data.id_immeuble_sav}}</p>
    <br>
    <mat-divider></mat-divider>
    <br>
  
   
        <span > <strong>Diagnostic en cours stit : </strong> {{data.diag_en_cours_stit}}</span>
        <mat-divider></mat-divider>
        <span *ngIf="data.numero_cas"> <strong>Diagnostic Tiers :</strong> {{data.diagnostic_tiers}}</span>
        <span *ngIf="data.ticket_stit" > <strong>Diagnostic Stit :</strong> {{data.diagnostic_stit}}</span>
        <mat-divider></mat-divider>
        <p><strong>Code de cloture</strong> : {{data.code_de_cloture}}  </p>
   
    <p><strong>Date de fermeture :</strong> {{data.date_cloture| date :
        'd MMMM yyyy, à HH:mm'}} {{data.date_cloture_stit| date :
            'd MMMM yyyy, à HH:mm'}}</p>



<div>

</div>

  