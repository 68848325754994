import { Component, OnInit } from '@angular/core';
import { slice } from 'lodash';
import { AlfrescoFileModel } from 'src/app/models/alfrescoNode';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-maia-quotidien',
  templateUrl: './maia-quotidien.component.html',
  styleUrls: ['./maia-quotidien.component.css']
})
export class MaiaQuotidienComponent implements OnInit {
  constructor(protected apiService :ApiService){}

  fileListing : AlfrescoFileModel[]= [];

  ngOnInit(): void {


  this.maiaQuotidie()

    
   
  }


  formatDate(dateString: string): string {
    
    const date = new Date(dateString);
    
    let day = String(date.getDate()).padStart(2, '0'); // Obtenir le jour
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Obtenir le mois (0-indexed)
    let year = date.getFullYear(); // Obtenir l'année

    return `${day}/${month}/${year}`; // Formatage dd/mm/yyyy
  }


  maiaQuotidie(): AlfrescoFileModel[] {

    this.apiService.getFolderListAlfrescoMaia().subscribe(
      (res) => {
        this.fileListing = res.items;
        this.fileListing =this.fileListing.reverse();
        
        let date =
                  this.fileListing[0].fileName.replace("maia_THD 64",'')
                      .slice(0).replace('.csv', '');

              
      }
      
    )
    return this.fileListing;
  }

  downloadIpe(workspace: string, filename :string) {
    let node='';
     node=workspace.replace("workspace://SpacesStore/", '');
     this.apiService.getFileFromAlfresco(node, filename)
     
     }


}


